/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { SanityButtonLink } from '../../../graphql-types';
import { useCurrentPath } from '../../hooks/use-current-path';
import track from '@parsleyhealth/cilantro-track';
import { Link as ScrollLink } from 'react-scroll';

import {
  Button,
  TButtonVariants,
  ButtonProps
} from '@parsleyhealth/cilantro-ui';

interface SanityButtonLinkProps extends SanityButtonLink {
  _type?: 'buttonLink';
  size?: ButtonProps['size'];
  px?: ButtonProps['px'];
  width?: string;
  children?: any;
}

const ButtonWithLink: React.FC<SanityButtonLinkProps> = ({
  children,
  destination,
  openInNewTab,
  trackingName,
  variant,
  ...buttonProps
}) => {
  const path = useCurrentPath();

  const handleClick = () => {
    track('Clicked CTA', {
      buttonText: `${children}`,
      category: 'Clicked CTA',
      destination: destination ?? 'no-redirect',
      label: trackingName,
      path: path
    });
  };
  const targetProps = openInNewTab && {
    target: '_blank',
    rel: 'noopener noreferrer'
  };
  // If destination is a full URL or is a Telephone Number, return external link
  const regex = /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  if (
    destination.includes('tel:') ||
    regex.test(destination) ||
    destination.includes('mailto:')
  ) {
    return (
      <Button
        variant={variant as TButtonVariants}
        onClick={() => handleClick()}
        href={destination}
        target={targetProps}
        {...buttonProps}
      >
        {children}
      </Button>
    );
  }

  const splitUrl = destination.split('#');

  if (splitUrl[1]) {
    return (
      <ScrollLink
        onClick={() => handleClick()}
        smooth={true}
        to={splitUrl[1]}
        offset={-70}
      >
        <Button
          variant={variant as TButtonVariants}
          aria-label={`${children}`}
          {...buttonProps}
        >
          {children}
        </Button>
      </ScrollLink>
    );
  }
  return (
    <Button
      href={destination}
      onClick={() => handleClick()}
      variant={variant as TButtonVariants}
      aria-label={`${children}`}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLink;
